<template>
  <v-card class="course-box-1" :color="exercise.color" light>
    <!--    <v-img :src="course.images.src" aspect-ratio="1.7" height="50%"></v-img>-->
    <v-card-subtitle class="text-start" primary-title>
      <v-row>
        <v-col cols="6" class="max-line h4 white--text font-weight-bold mt-3 cursor" @click="detail(exercise)">
          &nbsp;&nbsp;{{ exercise.title }}
        </v-col>
        <v-col cols="6" class=" h4 white--text font-weight-bold text-end ">
          <v-btn
              @click="deleteEx(exercise.id)"
              icon
              color="#FFFFFF"
          >
            <v-icon left>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-actions class="cursor" @click="detail(exercise)">
      <v-list-item>
        <v-list-item-content>
          <div class="white--text h5 " >{{ exercise.description }}</div>
        </v-list-item-content>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>

<script>
import {DELETE_EXERCISE, GET_LIST_EXERCISE} from "@/store/exercise-toeic.module";

export default {
  props: ["exercise"],
  emit: ['deleteEx'],
  methods: {
    deleteEx(id) {
      const _this = this;
      confirm("Are you sure you want to delete this item?") &&
      this.$store
          .dispatch(DELETE_EXERCISE, id).then(()=> {
          _this.$store.dispatch(GET_LIST_EXERCISE);
      })
    },
    detail(item) {
      this.$router.push({
        path: '/exercise',
        query: { slug: item.slug,viewType:1 }})
    }
  },

};
</script>

<style>
.cursor {
  cursor: pointer;
}

.course-box-1 {
  width: 268px;
  height: 156px;
  border: none !important;
  border-radius: 10px 10px !important;
  font-style: normal;
  font-size: 12px;
  line-height: 21px;
}

.max-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.theme--light.v-card .v-card__subtitle {
  color: black !important;
}

.v-card__subtitle {
  font-weight: bold !important;
  font-size: 1rem !important;
  padding: 12px !important;
}
</style>
