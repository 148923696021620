<template>
  <v-app>
    <v-container class="grey lighten-5 p-5">
      <v-row>
        <v-card class="course-box-1 align-center d-flex flex-row ma-2">
          <v-card-text height>
            <v-row class="justify-center text-center d-flex">
              <v-col cols="12">
                <router-link to="/exercise/create">
                  <v-icon>mdi-plus</v-icon>
                  <div>Thêm bài tập</div>
                </router-link>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div  class="d-flex flex-row ma-2" v-for="(exercise,index) in getListExercise" :key="index">
            <exercise-box :exercise="exercise"/>
        </div>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import exerciseBox from "@/components/exercise-toeic-model/exercise-box";
import { GET_LIST_COURSE } from "@/store/courses.module";
import {GET_LIST_EXERCISE,DELETE_EXERCISE} from "@/store/exercise-toeic.module";

export default {
  components: {
    exerciseBox,
  },
  data: () => ({
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
  }),
  mounted() {
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Danh sách khóa học",titledad:'Khóa học' }]);
    this.$store.dispatch(GET_LIST_EXERCISE);
  },
  created() {
    console.log(this.getListExercise)
    this.$store.dispatch(GET_LIST_EXERCISE, {});
  },
  computed: {
    ...mapGetters({
      getListExercise: "getListExercise"
    }),
    ...mapState({

      courses: (state) => state.courses.courses,
    }),
  },
  methods: {
    action() {
      alert("hello");
    },

  },
};
</script>

<style scoped>
.search-bar {
  margin: 0 5px !important;
}
</style>
